import * as React from 'react';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../../components/layout/Layout';
import ServiceCard from '../../components/card/Service';
import CountsSection from '../../components/section/Counts';

// Services
const ServicesPage = ({ pageContext, data }) => {
  const { totalCount } = data.services
  const pageTitle = `Services - ${totalCount} service${totalCount === 1 ? "" : "s"}`

  const headerBackgroundImageRandom = Math.floor(Math.random() * data.services.nodes.length);
  const headerBackgroundImage = data.services.nodes[headerBackgroundImageRandom].frontmatter.image.cover ? data.services.nodes[headerBackgroundImageRandom].frontmatter.image.cover.childImageSharp.fluid : null;
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.1))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`${pageTitle} | ${data.site.siteMetadata.title} - Un cercle de qualité`}
      pageDescription={`L'équipe dédiée et qualifiée de ${data.site.siteMetadata.title} saura vous apporter toutes les informations et le savoir-faire pour la bonne réalisation de votre projet personnel ou professionnel.`}
      pageKeywords="moussé gava, service, le coteau, mably, roannais, rénovation, plâtrerie-peinture, isolation thermique, décoration, aménagement, sols souples"
      headerClassName="wpo-site-header wpo-header-style-2"
    >
      <BackgroundImage
        Tag="section"
        className="wpo-page-title"
        fluid={headerBackgroundImageStack}
      >
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <h2>Nos services</h2>
                <ol className="wpo-breadcumb-wrap">
                  <li><Link to={`/`}>Accueil</Link></li>
                  <li>Services</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {data.services && data.services.nodes.length ?
        <div id="services" className="wpo-service-area section-padding">
          <div className="container">
            <div className="row">
              <div className="wpo-section-title">
                <span>Nos compétences</span>
                <h2>Ce que nous faisons</h2>
              </div>
            </div>
            <div className="row align-items-top">
              {data.services.nodes.map(service => (
                <ServiceCard key={service.id} data={service} cardStyle="1" cardClassName="col-lg-4 col-md-6 col-12 mb-4" />
              ))}
            </div>
          </div>
        </div>
      : null}
      <CountsSection site={query.site} sectionStyle="2"/>
    </Layout>
  )
}

export const query = graphql`
  query {  
    site: site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image {
          alt
        }
      }
    }
    services: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/services/" }, 
        frontmatter: { active: { eq: true } }
      },
      sort: {fields: frontmatter___order, order: ASC}
    ) {
      nodes {
        frontmatter {
          title
          description
          image {
            icon {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            cover {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            card {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            credit {
              text
              link
            }
          }
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
      totalCount
    }
  }
`

export default ServicesPage